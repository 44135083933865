// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiEndpoint: `https://api.adminluvsbrownies.com`,
  // apiEndpoint: `https://77da4636daf2.ngrok.io`
  // apiEndpoint: `http://52.37.164.18:3002`
  // apiEndpoint: `http://localhost:3002`
  //apiEndpoint: `http://3.19.102.40:3001`
};
