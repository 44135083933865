import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'order'
})
export class OrderPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch(value){
      case 1:
        return {CLASS: 'badge badge-secondary',STATUS:'PENDING'};
      case 2:
        return {CLASS: 'badge badge-success',STATUS:'COMPLETED'};
      case 3:
        return {CLASS: 'badge badge-danger',STATUS:'CANCELLED'};
      case 4:
        return {CLASS: 'badge badge-success',STATUS:'REFUNDED'};
      default:
        return 'Not set';
    }
  }

}

@Pipe({
  name: 'truckorder'
})
export class TruckOrderPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch(value){
      case 1:
        return {CLASS: 'badge badge-secondary',STATUS:'PENDING'};
      case 2:
        return {CLASS: 'badge badge-success',STATUS:'ACCEPTED'};
      case 3:
        return {CLASS: 'badge badge-danger',STATUS:'REJECTED'};
      default:
        return 'Not set';
    }
  }

}
@Pipe({
  name: 'truckrequesttype'
})
export class TruckRequestOrderPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch(value){
      case 1:
        return 'Near By Area';
      case 2:
        return 'Specail Event';
      default:
        return 'Not set';
    }
  }

}
@Pipe({
  name: 'guestcount'
})
export class GuestCountPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch(value){
      case 1:
        return 'Upto 50';
      case 2:
        return '51 to75';
      case 3:
        return '76-100';
      default:
        return 'Not set';
    }
  }

}
@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: any): any {
    if(value){
      return `(${value.substr(0,3)}) ${value.substr(3,3)}-${value.substr(6)}`
    }
    return null
  }

}