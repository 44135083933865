import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  confirmObj;
  obj;
  verifyObj;
  inputField: any;
  constructor() {
    this.confirmObj = {
      type: '',
      title: '',
      text: '',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    };
    this.verifyObj = {
      title: '',
      input: '',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      preConfirm: '',
      allowOutsideClick: () => !Swal.isLoading()
    }
    this.obj = {
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000
    };

    this.inputField = {
      title: '',
      input: '',
      inputValue: '',
      showCancelButton: true,
      inputPlaceholder: '',
      inputValidator: (value) => {
        if (!value) {
          return ''
        }
      }
    }
  }
  /**
   * Warning popup
   * @param title 
   * @param text 
   */
  warning(title, text) {
    Swal.fire({
      type: 'warning',
      title: title,
      text: text
    });
  }

  /**
   * success popup
   * @param title 
   * @param text 
   */
  success(title, text) {
    Swal.fire({
      type: 'success',
      title: title,
      text: text
    });
  }

  /**
   * simple popup
   * @param type1 
   * @param title1 0
   */
  simplePopUp(type1, title1, text1) {
    Swal.fire({
      type: type1,
      title: title1,
      text: text1
    });
  }

  /**
   * Confirm popup
   * @param type 
   * @param title 
   * @param text 
   * @param confirm 
   * @param cancel 
   */
  confirmPopUp(type, title, text) {
    this.confirmObj.type = type;
    this.confirmObj.title = title;
    this.confirmObj.text = text;
    // this.confirmObj.confirmButtonText = confirm;
    // this.confirmObj.cancelButtonText = cancel;
    return Swal.fire(this.confirmObj);
  }

  /**
   * Verify popup
   * @param title 
   * @param input 
   * @param preconfirm 
   */
  verifyPopup(title, input, preconfirm) {
    this.verifyObj.title = title;
    this.verifyObj.input = input;
    this.verifyObj.preConfirm = preconfirm;
    return Swal.fire(this.verifyObj);
  }

  /**
   * Normal toast show
   * @param obj 
   */
  toastShow(obj) {
    const toast = Swal.mixin(this.obj);
    toast.fire(obj);
  }

  /**
   * warning Toast show
   * @param title 
   */
  toastWarning(title) {
    const Obj = {};
    Obj['type'] = 'error';
    Obj['title'] = title;
    const toast = Swal.mixin(this.obj);
    toast.fire(Obj);

  }

  /**
   * Success toastshow
   * @param title 
   */
  toastSuccess(title) {
    const Obj = {};
    Obj['type'] = 'success'
    Obj['title'] = title;
    const toast = Swal.mixin({ ...this.obj, ...Obj });
    // toast.fire(Obj);
    toast.fire(Obj);

  }

  setInputField(inputConfig) {
    let config={...inputConfig}
    // this.inputField.title = inputConfig.title;
    // this.inputField.input = inputConfig.input;
    // this.inputField.inputPlaceholder = inputConfig.inputPlaceholder;
    // this.inputField.inputValue='inputConfig.value';
    return Swal.fire(config);
  }

}
