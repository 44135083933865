import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthUserService } from '../services/auth-service/auth-user.service';
import { AlertService } from '../services/alert-service/alert.service';
import { USERDATA } from '../../common/constants';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];
    constructor(
        public router: Router,
        public authUserService: AuthUserService,
        public alertService: AlertService
    ) { }

    /** Request interceptor **/
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authUserService.isAuthenticated()) {
            const token = this.authUserService.getUserDetails(USERDATA.TOKEN);
            req = req.clone({ headers: req.headers.set('authorization', token) });
        }
        this.requests.push(req);


        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                // console.log("event", event, typeof (event));

                if (event instanceof HttpResponse) {
                    this.removeRequest(req);
                    // if (event && event.body.status != 200) {
                    // this.authUserService.logout();
                    // this.router.navigate(['/login']);
                    // }
                } else {
                    console.log(event);
                }
            },
                (err: any) => {
                    this.removeRequest(req);
                    if (err instanceof HttpErrorResponse) {
                        const error = err.error;
                        // console.log(error);
                        // this.alertService.toastWarning(error.message);

                        if (error.statusCode === 401) {
                            this.authUserService.logout();
                            this.alertService.toastWarning("Unauthorized User");
                            this.router.navigate(['/login']);
                        }
                        // else if (error.statusCode === 401 || error.statusCode === 500) {

                        // }
                    }
                })
        );
    }
    /** Remove request **/
    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        this.requests.splice(i, 1);
    }
}