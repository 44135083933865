import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../../core/services/alert-service/alert.service';
import { AuthUserService } from '../../core/services/auth-service/auth-user.service';
import { HttpService } from '../../core/services/http-service/http.service';
import { APIS } from '../../common/constants';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  loginForm: FormGroup;
  isSubmitted: boolean = false;
  mismatch = false;
  tax: number;
  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private route: Router,
    private authService: AuthUserService,
    // private loaderService: LoaderService,
    private alertService: AlertService
  ) {

    this.loginForm = this.fb.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.httpService.getData(APIS.get_tax).subscribe(res => {
      this.tax = (res.data || {}).tax || 0
    })
  }


  forgotPassword() {
    this.route.navigate(['/forgot-password']);
  }

  get controls() {
    return this.loginForm.controls;
  }
  matchPass(event) {
    if (this.loginForm.value.password != this.loginForm.value.confirm_password) {
      this.mismatch = true;
    } else {
      this.mismatch = false;
    }
  }
  onSubmit() {
    this.isSubmitted = true;
    if (this.loginForm.invalid || this.mismatch) {
      return;
    }

    this.httpService.postData(APIS.change_pass, { password: this.loginForm.value.password }).subscribe(response => {
      this.alertService.toastSuccess("Password successfully changed");
    }, (error) => {
      this.alertService.toastWarning(error.error.msg);
      console.log("error", error.error.msg);
    });
  }
  saveTax() {
    if (this.tax) {
      this.httpService.putData(APIS.tax, { tax: this.tax }).subscribe(res => {
        this.alertService.toastSuccess('Tax updated successfully')
      })
    }
  }
}
