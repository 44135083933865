import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../core/services/http-service/http.service';
import { AlertService } from '../../core/services/alert-service/alert.service';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() headerObj;

  @Input() statusCode;
  @Input() url;
  records: any;
  searchUser;
  totalCounts: number;
  page: number = 0;
  limit: number = 10;

  constructor(
    private httpService: HttpService,
    private route: Router,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.getRecords({ index: this.page, limit: this.limit });
  }

  search() {
    this.httpService.postData(this.url, {}).subscribe((result) => {
      console.log(result);
    })
  }

  getRecords(data) {
    this.httpService.getData(this.url, data).subscribe(res => {
      this.records = res.data.items;
      this.totalCounts = res.data.totalCounts;
    });

  }

  changeStatus(obj) {
    this.httpService.putData(`${this.url}/${obj._id}/status`, { isActive: !obj.isActive }).subscribe(res => {
      obj.isActive = res.data.isActive;
    })
  }

  getDate(dateStr) {
    const date = new Date(dateStr);
    return `${date.getDay()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  editRecord(obj, record) {
    this.route.navigate([obj.editrecordurl, record._id]);
  }

  deleteRecord(record) {
    this.httpService.deleteData(`${this.url}/${record._id}`).subscribe(res => {
      this.getRecords({ index: this.page, limit: this.limit });
    })
  }
  pageChange(event) {
    console.log(event);
  }
}
