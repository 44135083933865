
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { USERDATA } from '../../../common/constants';

@Injectable({ providedIn: 'root' })
export class AuthUserService {
  UserDetails = {
    Token: 'token'
  }
  constructor(private router: Router) { }

  /** User authenticated or not **/
  isAuthenticated(): boolean {
    if (localStorage.getItem(USERDATA.TOKEN)) {
      return true;
    }
    return false;
  }

  /** User logout **/
  logout() {
    localStorage.clear();
  }

  /** Set user details **/
  setUserDetails(key, value) {
    localStorage.setItem(key, value);
  }

  /** Get user details **/
  getUserDetails(key) {
    return localStorage.getItem(key);
  }
}
