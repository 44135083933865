import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { HttpService } from '../../core/services/http-service/http.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { TableService } from '../../core/services/table.service';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() modules: any;
  @Input() subjects: any;
  @Output() onFilter = new EventEmitter();
  @Output() onSubjectChange = new EventEmitter();
  filter: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.formInit()
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
      this.filter.controls.subjectId.setValue(this.subjects[0]._id)
  }
  formInit() {
    this.filter = this.fb.group({
      subjectId: [''],
      // moduleId: [''],
      // searchString: ['']
    })
  }

  onfilterEvent() {
    const data = _.pickBy(this.filter.value, val => ![undefined, null, ''].includes(val));
    this.onFilter.emit(data);
  }
  getModules(subjectId: string) {
    this.onSubjectChange.emit(subjectId);
  }

}
