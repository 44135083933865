import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { APIS } from '../../common/constants';
import { AlertService } from '../../core/services/alert-service/alert.service';
import { HttpService } from '../../core/services/http-service/http.service';

@Component({
  selector: 'app-truck-request',
  templateUrl: './truck-request.component.html',
  styleUrls: ['./truck-request.component.scss']
})
export class TruckRequestComponent implements OnInit {
  orders = [];
  totalDocuments: number;
  paginate = { skip: 0, limit: 10 };
  p = 1;
  filterData = {}
  constructor(
    private httpService: HttpService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.getOrders({ skip: 0, limit: 10 });
  }
  getOrders(data?) {
    this.httpService.getData(APIS.truck_orders, data).subscribe(res => {
      this.orders = res.data.orders;
      this.totalDocuments = res.data.totalDocuments;
    });
  }
  next(event) {
    this.paginate.skip = (event - 1) * this.paginate.limit;
    this.p = event;
    this.filterData = _.pickBy(this.filterData, (val) => ![undefined, '', null].includes(val));
    this.getOrders({ ...this.paginate });
  }
  updateOrderStatus(order, status) {
    this.alertService.confirmPopUp('warning', 'Are you sure?', (status == 3) ? 'Do you want to reject this request?' : 'Do you want to accept this request?').then(val => {
      if (val.value) {
        this.httpService.putData(`${APIS.truck_update}/${order._id}`, { status }).subscribe(res => {
          this.alertService.toastSuccess('Order Updated successfully');
          order.status = status;
        })
      }
    })
  }
  filter(event, filterType) {
    let data = {};
    if (event.target.value) {
      data = { filterType, filterValue: event.target.value };
    }
    this.filterData = data;
    this.getOrders(data);
  }
  deleteRequest(order) {
    this.alertService.confirmPopUp('warning', 'Are you sure?', 'This will remove record permanently.').then(val => {
      if (val.value) {
        this.httpService.deleteData(`${APIS.delete_truck}/${order._id}`).subscribe(res => {
          this.alertService.toastSuccess('Request deleted successfully');
          this.getOrders({ ...this.paginate });
        })
      }
    })
  }
}
