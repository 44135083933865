import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../core/services/http-service/http.service';
import { APIS } from '../../common/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../core/services/alert-service/alert.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  notification: string;
  form: FormGroup;
  isSubmit: boolean = false;
  constructor(
    private httpService: HttpService,
    private route: Router,
    private fb: FormBuilder,
    private alertService: AlertService
  ) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      minPinCode: ['', Validators.required],
      maxPinCode: ['', Validators.required],
      notificationMessage: ['', Validators.required]
    })
  }
  get controls() {
    return this.form.controls;
  }
  onSubmit() {
    this.isSubmit = true;
    if (this.form.invalid) {
      return;
    }
    this.httpService.postData(APIS.push_notification, this.form.value).subscribe(res => {
      this.alertService.toastSuccess("Notification sent successfully");
      // this.form.reset();
      this.isSubmit = false;
    })
  }
}
