import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from '../../core/services/http-service/http.service';
import { AuthUserService } from '../../core/services/auth-service/auth-user.service';
import { Router } from '@angular/router';
import { APIS } from '../../common/constants';
import { AlertService } from '../../core/services/alert-service/alert.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPassword: FormGroup;
  isSubmitted: boolean = false;
  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private authService: AuthUserService,
    private route: Router,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.forgotPassword = this.fb.group({
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]]
    });
  }

  get controls() {
    return this.forgotPassword.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.forgotPassword.invalid) {
      return;
    }
    this.httpService.postData(APIS.ForgotPassword, { ...this.forgotPassword.value, isAdminCall: true }).subscribe(response => {
      this.route.navigate(['/reset-password']);
      // if (response.data) {
      // } else {
      //   this.route.navigate(['/forgot-password']);
      //   this.alertService.toastWarning(response.message);
      // }
    }, (error) => {
      this.alertService.toastWarning(error.error.msg);

      console.log(error);
    });
  }

}
