interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Item Library',
    url: '/products',
    icon: 'fa fa-cutlery'
  },
  {
    name: 'Categories',
    url: '/categories',
    icon: 'fa fa-tag',
    // children:[
    //   {
    //     name: 'Categories',
    //     url: '/categories',
    //     icon:'fa fa-tag'
    //   },
    //   {
    //     name: 'Sub-Category',
    //     url: '/categories/sub-category',
    //     icon:'fa fa-tag'
    //   },
    // ]
  },
  {
    name: 'Users',
    url: '/users',
    icon: 'fa fa-users',
    children: [
      {
        name: 'Customers',
        url: '/users/customers',
        icon: 'fa fa-tag'
      },
      {
        name: 'Drivers',
        url: '/users/drivers',
        icon: 'fa fa-tag'
      }
    ]
  },
  {
    name: 'Truck',
    url: '/truck',
    icon: 'fa fa-truck'
  },
  {
    name: 'Orders',
    url: '/orders',
    icon: 'fa fa-truck'
  },
  {
    name: 'Truck Request',
    url: '/truck-requests',
    icon: 'fa fa-truck'
  },
  {
    name: 'Notification',
    url: '/notification',
    icon: 'fa fa-bell'
  },
  {
    name: 'Settings',
    url: '/setting',
    icon: 'fa fa-tag'
  },
  {
    name: 'Logout',
    url: '/login',
    icon: 'fa fa-power-off'
  }
  // {
  //   name: 'Drivers',
  //   url: '/drivers',
  //   icon:'fa fa-drivers-license'
  // },
];
