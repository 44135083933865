import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APIS } from '../../../common/constants';
import { HttpService } from '../../../core/services/http-service/http.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  type:number;
  title:string;
  category:any;
  categoryId:string;
  category_list:any=[];
  name:string;
  img_url:string;
  subcategory_name:string;
  subcategory_img_url:string;
  subCategories=[];
  isSubmit:boolean;
  id:string;

  constructor(
    private httpService:HttpService,
    private router:Router,
    private activatedRouter:ActivatedRoute,
    public modal: NgbActiveModal
  ) { }

  ngOnInit() {
    if(this.category){
      this.name=this.category.name;
      this.img_url=this.category.imageUrl;
        if(this.type==2){
        this.categoryId=this.category.categoryId;
        this.httpService.getData(APIS.get_category).subscribe(res=>{
          this.category_list=res.data.categories;
        })
      }
    }
  }

  uploadFile(event) {
    let data = new FormData();
    data.append('image', event.target.files[0]);
    this.httpService.postData(APIS.DocumentUpload,data).subscribe(res=>{
        this.img_url=res.fileUrl;
    })
  }
  onSubmit(){
    this.isSubmit=true;
    if(!this.name || !this.img_url ){
      return;
    }
    if(this.type==2 && !this.categoryId){
      return;
    }
    let data={name:this.name, imageUrl:this.img_url}
    if(this.type==2){
      data['categoryId']=this.categoryId;
    }
    this.modal.close(data);
  }
  close(){
    this.modal.close();
  }
}
