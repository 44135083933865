import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthUserService } from './core/services/auth-service/auth-user.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './core/interceptors/http.interceptor';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { LoaderService } from './core/services/loader-service/loader.service';
import { SharedModule } from './shared/shared.module';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { AlertService } from './core/services/alert-service/alert.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddComponent } from './views/category/add/add.component';
import { TruckRequestComponent } from './views/truck-request/truck-request.component';
import { GuestCountPipe } from './core/pipes/order.pipe';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { NotificationComponent } from './views/notification/notification.component';
// import { TruckScheduleComponent } from './views/truck/truck-schedule/truck-schedule.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
// import { AddCategoryComponent } from './views/etnry-components/add-category/add-category.component';
// import { AddComponent } from './views/items/add/add.component';


@NgModule({
  imports: [
    GooglePlaceModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    TabsModule.forRoot(),
    ChartsModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AddComponent,
    GuestCountPipe,
    TruckRequestComponent,
    ChangePasswordComponent,
    NotificationComponent,
    // TruckScheduleComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },

    AuthUserService,
    LoaderService,
    AlertService,
    NgbActiveModal
  ],
  bootstrap: [AppComponent],
  exports: [SharedModule],
  entryComponents: [AddComponent]
})
export class AppModule { }
