import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LoaderComponent } from './loader/loader.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { TruncatePipe } from '../core/pipes/truncate.pipe';
import { RolePipe, StatusPipe, UnitPipe } from '../core/pipes/status.pipe';
import { FilterComponent } from './filter/filter.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderPipe, PricePipe, TruckOrderPipe, TruckRequestOrderPipe } from '../core/pipes/order.pipe';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";


@NgModule({
  declarations: [TableComponent, StatusPipe, RolePipe, LoaderComponent, ComingSoonComponent, TruncatePipe, FilterComponent, UnitPipe, OrderPipe, TruckRequestOrderPipe, TruckOrderPipe, PricePipe],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    NgbModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    GooglePlaceModule
  ],
  exports: [
    TableComponent,
    LoaderComponent,
    StatusPipe,
    CommonModule,
    NgbModule,
    ComingSoonComponent,
    TruncatePipe,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    FilterComponent,
    BsDropdownModule,
    UnitPipe,
    RolePipe,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OrderPipe,
    TruckRequestOrderPipe,
    TruckOrderPipe,
    PricePipe,
    GooglePlaceModule
  ]
})
export class SharedModule { }
