import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from '../../core/services/http-service/http.service';
import { APIS, USERDATA } from '../../common/constants';
import { Router } from '@angular/router';
import { AuthUserService } from '../../core/services/auth-service/auth-user.service';
import { LoaderService } from '../../core/services/loader-service/loader.service';
import { AlertService } from '../../core/services/alert-service/alert.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isSubmitted: boolean = false;
  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private route: Router,
    private authService: AuthUserService,
    private loaderService: LoaderService,
    private alertService: AlertService
  ) {

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]],
      password: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.authService.logout();
  }


  forgotPassword() {
    this.route.navigate(['/forgot-password']);
  }

  get controls() {
    return this.loginForm.controls;
  }

  onSubmit() {

    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.httpService.postData(APIS.login, this.loginForm.value).subscribe(response => {
      this.authService.setUserDetails(USERDATA.TOKEN, response[USERDATA.TOKEN]);
      this.route.navigate(['/products']);
      if (response.token) {
        this.authService.setUserDetails(USERDATA.TOKEN, (response || {}).token);
      } else {
        // this.alertService.toastWarning(response.message);
        this.route.navigate(['/login']);
      }
    }, (error) => {
      this.alertService.toastWarning(error.error.msg);
      console.log("error", error.error.msg);
    });
  }
}
