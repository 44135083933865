import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { APIS } from '../../common/constants';
import { HttpService } from '../../core/services/http-service/http.service';
import { AuthUserService } from '../../core/services/auth-service/auth-user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPassword: FormGroup;
  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private route: Router
  ) { }

  ngOnInit() {

  }

  onSubmit() {
    this.route.navigate(['/login']);
  }

}
