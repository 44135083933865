export const ApplicationConstants = {
    AdminName: 'Admin',
    AppName: "Luv'sBrownies"
}
export const APIS = {
    get_product: '/v1/products',
    product: '/v1/product',
    DocumentUpload: '/v1/file/upload',
    truck: '/v1/truck',
    login: '/v1/admin/login',
    get_category: '/v1/categories',
    category: '/v1/category',
    subcategory: '/v1/subCategory',
    get_sub_cateory: '/v1/subCategories',
    places: '/v1/admin/mapsLocations',
    users: '/v1/admin/users',
    user: '/v1/admin/user',
    get_user: '/v1/user/getUser',
    get_orders: '/v1/orders',
    truck_orders: '/v1/truck/requests/all',
    truck_update: '/v1/truck/request',
    order: '/v1/order',
    refund: '/v1/order/refund',
    cancel: '/v1/order/cancel',
    change_pass: '/v1/admin/changePassword',
    ForgotPassword: '/v1/user/forgotPassword',
    tax: '/v1/admin/tax',
    get_tax: '/admin/tax',
    push_notification: '/admin/sendPush',
    delete_truck: '/v1/truck/request'
}
export const UNIT = {
    per_item: 1,
    per_hour: 2,
    per_liter: 3,
    per_ounce: 4,
    per_pound: 5,
    per_yard: 6
}
export const FILTER_TYPES = {
    ORDER_STATUS: 1,
    PAYMENT_TYPE: 2
};
export const TRUCK_REQUEST_STATUSES = {
    PENDING: 1,
    ACCEPTED: 2,
    REJECTED: 3
};
export const ORDER_STATUSES = {
    PENDING: 1,
    COMPLETED: 2,
    CANCELLED: 3,
    REFUNDED: 4
};
export const PaymentStatus = [
    // { status: 'failed', class: 'badge badge-danger' },
    // { status: 'pending', class: 'badge badge-warning' },
    // { status: 'success', class: 'badge badge-success' }
]
export const TableData = {
    Users: [
        { name: 'Sr No.', key: 'id' },
        { name: 'Name', key: 'firstName' },
        { name: 'Email', key: 'email' },
        { name: 'Blocked', key: 'isBlocked' },
        { name: 'Active', key: 'isActive' },
        { name: 'Login Type', key: 'loginType' },
        { name: 'Date of Creation', key: 'createdAt', key2: 'date' },
        { name: 'Action', key: 'action' },
    ],
    Documents: [
        { name: 'Exam Type', key: 'examType' },
        { name: 'Paper', key: 'paper' },
        { name: 'Year', key: 'year' },
        { name: 'Status', key: 'isActive', class: 'link', type: 'isActive' },
        { name: 'Created At', key: 'createdAt', type: 'date' },
        { name: 'Action', key: 'action', editrecordurl: '/documents/edit-document', type: 'action' },
    ],
    Events: [
        { name: 'Title', key: 'title' },
        { name: 'Start Date', key: 'startDate', type: 'date' },
        { name: 'End Date', key: 'endDate', type: 'date' },
        { name: 'Status', key: 'isActive', class: 'link', type: 'isActive' },
        { name: 'Created At', key: 'createdAt', type: 'date' },
        { name: 'Action', key: 'action', editrecordurl: '/events/edit-event', type: 'action' },
    ],
    Quiz: [
        { name: 'Title', key: 'title' },
        { name: 'Paid', key: 'isPaid' },
        { name: 'Price', key: 'price' },
        { name: 'Status', key: 'isActive', class: 'link', type: 'isActive' },
        { name: 'Time Limit', key: 'timeLimit' },
        { name: 'Total Question', key: 'totalQuestions' },
        { name: 'Action', key: 'action', editrecordurl: '/quiz/edit-quiz', type: 'action' },
    ],
    Seriese: [
        { name: 'Title', key: 'title' },
        { name: 'Price', key: 'price' },
        { name: 'Subject Name', key: 'subjectData', type: 'object', subkey: 'subjectName' },
        { name: 'Total Quizzes', key: 'quizIds', type: 'count' },
        { name: 'Status', key: 'isActive', class: 'link', type: 'isActive' },
        { name: 'Action', key: 'action', editrecordurl: '/quiz/edit-quiz', type: 'action' }
    ]
}

export enum QUESTION_METHOD {
    MANUAL = 'manual',
    SHEET = 'sheet'
}

export const PAGINATION_DATA = {
    index: 0,
    limit: 10
}
export const USERDATA = {
    TOKEN: 'token'
}
