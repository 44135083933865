import { Pipe, PipeTransform } from '@angular/core';
import { UNIT } from '../../common/constants';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return (value) ? { STATUS: 'Active', CLASS: 'badge badge-success' } : { STATUS: 'In-Active', CLASS: 'badge badge-danger' };
  }

}
@Pipe({
  name: 'unit'
})
export class UnitPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch(parseInt(value)){
      case UNIT.per_item:
        return 'Per Item';
      case UNIT.per_hour:
        return 'Per Hour';
      case UNIT.per_liter:
        return 'Per Liter';
      case UNIT.per_ounce:
        return 'Per Ounce';
      case UNIT.per_pound:
        return 'Per Pound';
      case UNIT.per_yard:
        return 'Per Yard';
    }
  }

}
@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(value){
      return (value==1)?'Customer':'Driver';
    }
    return 'NA'
  }

}
