//loader.interceptor.ts
import { Component, OnInit, AfterContentInit, AfterViewInit } from '@angular/core';
import { LoaderService } from '../../core/services/loader-service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, AfterViewInit {

  show = false;
  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.loaderService.isLoading.subscribe((value) => {
      this.show = value;
    });
  }
}

